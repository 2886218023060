import { useEffect, useState } from "react";
import { useStateContext } from "../context/ContextProvider";
import PasswordHider from "../components/PasswordHider";
import { toastNotification } from "../utils/toastNotification";
import { updateUserPassword } from "../utils/api";
import { useNavigate } from "react-router-dom";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "@/components/ui/input-otp";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";

const PasswordReset = () => {
	const { OTP, setLoggedInUser, passwordResetEmail } = useStateContext()!;

	const navigate = useNavigate();

	const [userOTP, setUserOTP] = useState("");
	const [isOtpValid, setIsOtpValid] = useState(false);

	const [userPassword, setUserPassword] = useState("");
	const [userConfirmedPassword, setUserConfirmedPassword] = useState("");
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const form = useForm({
		defaultValues: {
			otp: "",
		},
	});

	useEffect(() => {
		setIsOtpValid(+OTP === +userOTP);
	}, [userOTP]);

	const handleCheckOTP = () => {
		if (!isOtpValid) {
			toastNotification("Code is invalid", "bg-error text-white");
			return;
		}
	};

	const handlePasswordReset = async () => {
		const passwordsMatch = userPassword === userConfirmedPassword;

		try {
			if (userPassword && userConfirmedPassword) {
				if (passwordsMatch) {
					const updatedUser = await updateUserPassword(
						passwordResetEmail,
						userPassword,
					);

					localStorage.setItem("loggedInUserInfo", JSON.stringify(updatedUser));

					const storedUserInfo = localStorage.getItem("loggedInUserInfo")!;
					setLoggedInUser(JSON.parse(storedUserInfo));

					toastNotification("Password successfully updated", "bg-success");

					setTimeout(() => {
						navigate("/profile");
						window.location.reload();
					}, 2000);
				} else {
					toastNotification("Passwords do not match", "bg-error text-base-100");
				}
			} else {
				toastNotification(
					"Please enter an email and a password",
					"bg-error text-base-100",
				);
			}
		} catch (error) {
			console.log(error);
			if (!userPassword)
				toastNotification("Please enter a password", "bg-error text-base-100");
		}
	};

	return (
		<div className="w-full flex justify-center items-center min-h-[60vh]">
			{!isOtpValid ? (
				<div className="flex flex-col justify-center items-center gap-10">
					<h1 className="text-2xl font-semibold">
						Please enter the code sent to your email.
					</h1>
					{/* INPUT OTP */}
					<Form {...form}>
						<form
							onSubmit={form.handleSubmit(handleCheckOTP)}
							className="w-2/3 space-y-6"
						>
							<FormField
								control={form.control}
								name="otp"
								render={({ field }) => (
									<FormItem>
										<FormLabel>One-Time Password</FormLabel>
										<FormControl>
											<InputOTP
												maxLength={6}
												{...field}
												pattern={REGEXP_ONLY_DIGITS}
												value={userOTP}
												onChange={(value) => setUserOTP(value)}
											>
												<InputOTPGroup>
													<InputOTPSlot index={0} />
													<InputOTPSlot index={1} />
													<InputOTPSlot index={2} />
													<InputOTPSlot index={3} />
													<InputOTPSlot index={4} />
													<InputOTPSlot index={5} />
												</InputOTPGroup>
											</InputOTP>
										</FormControl>
										<FormDescription>
											Please enter the one-time password sent to your phone.
										</FormDescription>
										<FormMessage />
									</FormItem>
								)}
							/>

							<Button type="submit" className="btn-primary">
								Submit
							</Button>
						</form>
					</Form>
				</div>
			) : (
				<div className="card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100">
					<div className="card-body">
						{/* PASSWORD */}
						<div className="form-control flex-2">
							<Label className="label">
								<span className="label-text">Password</span>
							</Label>
							<div className="flex">
								<input
									type={isPasswordVisible ? "text" : "password"}
									placeholder="password"
									className="input input-bordered flex-1"
									onChange={(e) => setUserPassword(e.target.value)}
									required
								/>
								<PasswordHider
									isPasswordVisible={isPasswordVisible}
									setIsPasswordVisible={setIsPasswordVisible}
								/>
							</div>
						</div>

						{/* CONFIRM PASSWORD */}
						<div className="form-control">
							<Label className="label">
								<span className="label-text">Confirm Password</span>
							</Label>
							<div className="flex">
								<input
									type={isPasswordVisible ? "text" : "password"}
									placeholder="retype password"
									className="input input-bordered flex-1"
									onChange={(e) => setUserConfirmedPassword(e.target.value)}
									required
								/>
								<PasswordHider
									isPasswordVisible={isPasswordVisible}
									setIsPasswordVisible={setIsPasswordVisible}
								/>
							</div>
						</div>
						<div className="form-control mt-6">
							<Button className="btn btn-primary" onClick={handlePasswordReset}>
								Confirm Reset
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PasswordReset;
