import type { SVGProps } from "react";
import type { JSX } from "react/jsx-runtime";

const WasherIllustrationSVG = (
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Layer_1"
		width="800"
		height="800"
		version="1.1"
		viewBox="0 0 496.8 496.8"
		{...props}
	>
		<title>Washer Illustration</title>
		<path
			fill="#80AFBA"
			d="M448.4 480.8c0 8.8-7.2 16-16 16h-368c-8.8 0-16-7.2-16-16v-464c0-8.8 7.2-16 16-16h360c8.8 0 16 7.2 16 16z"
		/>
		<path
			fill="#6FA4AD"
			d="M426.8 0c8.8 0 21.6 7.2 21.6 16v465.6c0 8.8-3.2 15.2-12 15.2h-368c-8.8 0-16.8-7.2-16.8-16"
		/>
		<path
			fill="#578D93"
			d="m448.4 304-96.8-91.2L152.4 412l85.6 84.8h198.4c8.8 0 12-6.4 12-15.2z"
		/>
		<circle cx="248.4" cy="305.6" r="146.4" fill="#C4E3E8" />
		<circle cx="248.4" cy="305.6" r="125.6" fill="#A3CAD3" />
		<circle cx="248.4" cy="305.6" r="114.4" fill="#4FC7D3" />
		<path fill="#59DCE2" d="M134 305.6c0-63.2 51.2-114.4 114.4-114.4" />
		<path
			fill="#48ACBC"
			d="M329.2 224.8c44.8 44.8 44.8 116.8 0 161.6s-116.8 44.8-161.6 0"
		/>
		<path fill="#C4E3E8" d="M374 368.8c-35.2 0-64-28.8-64-64s28.8-64 64-64" />
		<path
			fill="#6FA4AD"
			d="M428.4 16.8h-360c-8.8 0-20 8-20 16.8v95.2h392V33.6c0-8.8-3.2-16.8-12-16.8"
		/>
		<path
			fill="#A3CAD3"
			d="M428.4.8h-360c-8.8 0-20 6.4-20 15.2v88.8h392V16c0-8.8-3.2-15.2-12-15.2"
		/>
		<path
			fill="#BFE3E8"
			d="M448.4 16c0-8.8-12.8-16-21.6-16L64.4.8c-8.8 0-16 7.2-16 16"
		/>
		<path fill="#80AFBA" d="m426.8 0-82.4 104.8h104V16c0-8.8-12.8-16-21.6-16" />
		<path
			fill="#72A2A5"
			d="M192.4 84.8c0 2.4-1.6 4-4 4h-104c-2.4 0-4-1.6-4-4v-40c0-2.4 1.6-4 4-4h104c2.4 0 4 1.6 4 4z"
			opacity="0.2"
		/>
		<path
			fill="#86AD99"
			d="M192.4 76.8c0 2.4-1.6 4-4 4h-104c-2.4 0-4-1.6-4-4v-40c0-2.4 1.6-4 4-4h104c2.4 0 4 1.6 4 4z"
		/>
		<path
			fill="#658E78"
			d="M188.4 32.8c2.4 0 4 1.6 4 4v40c0 2.4-1.6 4-4 4h-104c-2.4 0-4-1.6-4-4"
		/>
		<path
			fill="#137D8E"
			d="M422.8 56c0 17.6-14.4 32-32 32s-32-14.4-32-32 14.4-33.6 32-33.6c17.6-.8 32 16 32 33.6"
		/>
		<path
			fill="#72A2A5"
			d="M422.8 56c0 17.6-14.4 46.4-32 46.4s-32-28.8-32-46.4 14.4-29.6 32-29.6c17.6-.8 32 12 32 29.6"
		/>
		<circle cx="390.8" cy="56" r="32" fill="#185B6B" />
		<path fill="#073B44" d="M413.2 33.6c12 12 12 32.8 0 44.8s-32.8 12-44.8 0" />
		<path
			fill="#B8D7E0"
			d="M409.2 40q0 2.4-2.4 2.4c-2.4 0-2.4-.8-2.4-2.4q0-2.4 2.4-2.4c2.4 0 2.4 1.6 2.4 2.4"
		/>
		<g fill="#A3CAD3">
			<circle cx="353.2" cy="278.4" r="5.6" />
			<circle cx="353.2" cy="304" r="5.6" />
			<circle cx="353.2" cy="329.6" r="5.6" />
		</g>
		<g fill="#185B6B">
			<circle cx="228.4" cy="54.4" r="13.6" />
			<circle cx="273.2" cy="54.4" r="13.6" />
			<circle cx="318" cy="54.4" r="13.6" />
		</g>
		<g fill="#80AFBA">
			<path d="M129.2 437.6c0 1.6-1.6 3.2-3.2 3.2H84.4c-1.6 0-3.2-1.6-3.2-3.2s1.6-3.2 3.2-3.2H126c2.4 0 3.2 1.6 3.2 3.2M129.2 456c0 1.6-1.6 3.2-3.2 3.2H84.4c-1.6 0-3.2-1.6-3.2-3.2s1.6-3.2 3.2-3.2H126c2.4 0 3.2 1.6 3.2 3.2M129.2 474.4c0 1.6-1.6 3.2-3.2 3.2H84.4c-1.6 0-3.2-1.6-3.2-3.2s1.6-3.2 3.2-3.2H126c2.4.8 3.2 1.6 3.2 3.2" />
		</g>
	</svg>
);

export default WasherIllustrationSVG;
