export const isWithinDeliveryRange = (
	collectionDateStr: string,
	deliveryMethodId: number,
): boolean => {
	const deliveryMethodTimeframes: { [key: number]: number } = {
		1: 3, // 3 days
		2: 1, // next day
	};

	const collectionDate = new Date(collectionDateStr);
	const deliveryTimeframe = deliveryMethodTimeframes[deliveryMethodId];
	const endDate = new Date(collectionDate);
	endDate.setDate(collectionDate.getDate() + deliveryTimeframe);

	const currentDate = new Date();

	return currentDate >= collectionDate && currentDate <= endDate;
};
