import { Link } from "react-router-dom";

import type React from "react";
import type { ServiceType } from "@/types";
import WasherIconSVG from "@/assets/SVG/WasherIconSVG";
import { getLowestPrice } from "@/utils/getLowestPrice";
import { ChevronRightIcon } from "@radix-ui/react-icons";

interface ServiceCardProps {
	serviceType: ServiceType;
	color: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ serviceType, color }) => {
	return (
		<Link
			to={`/laundry/services/${serviceType.shortCode}`}
			className={`w-full h-full flex flex-col justify-center items-center ${color} p-4 rounded-xl text-gray-700`}
		>
			<div className="w-full flex items-center mb-4">
				<div className="flex items-center justify-center w-10 h-10 bg-white/20 rounded-full flex-shrink-0">
					<WasherIconSVG className="w-6 h-6" />
				</div>
				<div className="ml-4">
					<h3 className="text-lg font-medium">{serviceType.title}</h3>
					<p className="text-xs text-gray-500 line-clamp-1">
						{serviceType.description}
					</p>
				</div>
			</div>

			<div className="relative flex items-center justify-between w-full p-2 rounded-full bg-gradient-to-r from-blue-50/30 to-blue-50/10">
				<p className="text-xs">
					from £
					<span className="text-lg font-bold">
						{getLowestPrice(serviceType?.serviceOptions) || "0.50"}
					</span>
				</p>
				<div className="flex items-center justify-center bg-white h-8 w-8 rounded-full">
					<ChevronRightIcon />
				</div>
			</div>
		</Link>
	);
};

export default ServiceCard;
