import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ContextProvider } from "./context/ContextProvider.tsx";
import { BrowserRouter } from "react-router-dom";

import { Analytics } from "@vercel/analytics/react";

import * as Sentry from "@sentry/react";

import.meta.env.MODE === "production" &&
	Sentry.init({
		dsn: "https://949dbbde19de3dd577cf2a262c94b663@o4506213529419776.ingest.sentry.io/4506213532762112",
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [
					"https://www.laundro.co.uk",
					/^https:\/\/laundro-client-[a-zA-Z0-9]+-laundros-projects\.vercel\.app\//,
					/^https:\/\/laundro-server-production.up.railway.app/,
				],
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

const rootElement = document.getElementById("root");

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<BrowserRouter>
				<ContextProvider>
					<App />

					{import.meta.env.MODE === "production" ? <Analytics /> : null}
				</ContextProvider>
			</BrowserRouter>
		</React.StrictMode>,
	);
}
