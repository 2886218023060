import WasherIllustrationSVG from "@/assets/SVG/WasherIllustrationSVG";

const OngoingOrderCard = ({ orderNumber }: { orderNumber: number }) => {
	return (
		<div className="w-full h-full flex justify-center items-center bg-gradient-to-tr from-blue-400 to-blue-600 rounded-xl py-6 gap-2">
			<div className="flex flex-col justify-center h-full mr-2 gap-2">
				<p className="text-xs text-gray-200 m-0 p-0 self-start">
					#{orderNumber}
				</p>
				<h3 className="text-lg font-semibold text-white m-0 p-0">
					Your order is in progress! 🎉
				</h3>
			</div>

			<WasherIllustrationSVG className="w-20 h-20" />
		</div>
	);
};

export default OngoingOrderCard;
