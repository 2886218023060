import { Link, useLocation } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";

const NavigationPills = () => {
	const context = useStateContext();
	const location = useLocation();

	if (!context) {
		return null;
	}

	const { serviceTypesData } = context;

	return (
		<div className="tabs tabs-boxed w-full sm:w-[500px] sm:flex justify-center items-center uppercase">
			{serviceTypesData
				.filter((serviceType) => serviceType.shortCode !== "ironing")
				.map((serviceType) => {
					const isActive =
						location.pathname === `/laundry/services/${serviceType.shortCode}`;

					return (
						<Link
							to={`/laundry/services/${serviceType.shortCode}`}
							className={`tab ${isActive ? "bg-primary text-white" : ""}`}
							key={serviceType.id}
						>
							{serviceType.shortCode}
						</Link>
					);
				})}
		</div>
	);
};

export default NavigationPills;
