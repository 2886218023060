import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AboutUs from "./pages/AboutUs";
import Checkout from "./pages/Checkout";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard/Dashboard";
import Duvets from "./pages/Services/Duvets";
import FAQs from "./pages/FAQs";
import Home from "./pages/Home";
import HowITWorks from "./pages/HowItWorks";
import Login from "./pages/Login";
import OrderConfirmation from "./pages/OrderConfirmation";
import OrdersAndSubscriptions from "./pages/OrdersAndSubscriptions";
import PasswordReset from "./pages/PasswordReset";
import PreRegistered from "./pages/PreRegistered";
import Profile from "./pages/Profile";
import PrivacyPolicy from "./pages/Footer/PrivacyPolicy";
import Services from "./pages/Services/Services";
import TermsOfUse from "./pages/TermsOfUse";
import WashAndDry from "./pages/Services/WashAndDry";
import WashDryAndIron from "./pages/Services/WashDryAndIron";

import Drawer from "./components/Drawer";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import { isUserAnAdmin } from "./utils/isUserAnAdmin";
import { useStateContext } from "./context/ContextProvider";
import NotFound from "./pages/NotFound";

const App = () => {
	const context = useStateContext();
	const [width, setWidth] = useState(window.innerWidth);
	const breakpoint = 700;

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	// SCROLL TO TOP ON PAGE CHANGE
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	if (!context) {
		return null;
	}

	const { loggedInUser, isModalOpen } = context;

	return (
		<div>
			{width < breakpoint ? <Drawer /> : <Navbar />}

			{isModalOpen ? (
				<div className="absolute top-0 left-0 w-screen h-screen bg-black opacity-60 z-50" />
			) : null}

			<div className="flex justify-center items-center mt-20 w-full">
				<Routes>
					{/* MAIN NAV LINKS */}
					<Route path="/" element={<Home />} />
					<Route path="/laundry/services" element={<Services />} />
					<Route path="/about" element={<AboutUs />} />
					<Route path="/how-it-works" element={<HowITWorks />} />
					<Route path="/faqs" element={<FAQs />} />

					{/* SERVICES */}
					<Route
						path="/laundry/services/wash-dry-fold"
						element={<WashAndDry />}
					/>
					<Route
						path="/laundry/services/wash-dry-iron"
						element={<WashDryAndIron />}
					/>
					{/* <Route path="/laundry/services/ironing" element={<Ironing />} /> */}
					<Route path="/laundry/services/duvets" element={<Duvets />} />

					{/* CHECKOUT */}
					<Route path="/checkout" element={<Checkout />} />
					{loggedInUser?.id && (
						<Route path="/order-confirmation" element={<OrderConfirmation />} />
					)}

					{/* LOGIN */}
					<Route path="/login" element={<Login />} />

					{/* PASSWORD RESET */}
					<Route path="/password-reset" element={<PasswordReset />} />

					{/* PROFILE */}
					{loggedInUser?.id && <Route path="/profile" element={<Profile />} />}
					{loggedInUser?.id && (
						<Route
							path="/orders-and-subscriptions"
							element={<OrdersAndSubscriptions />}
						/>
					)}

					{/* FOOTER LINKS */}
					<Route path="/terms-of-use" element={<TermsOfUse />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/contact" element={<Contact />} />

					{/* PRE LAUNCH */}
					<Route path="/pre-registered" element={<PreRegistered />} />

					<Route path="*" element={<NotFound />} />
				</Routes>
			</div>

			{isUserAnAdmin(loggedInUser) && (
				<Routes>
					<Route path="/dashboard" element={<Dashboard />} />
				</Routes>
			)}

			<ToastContainer
				position="top-center"
				closeOnClick
				hideProgressBar
				closeButton={false}
				autoClose={1000}
				limit={4}
			/>

			<Footer />
		</div>
	);
};

export default App;
