export const endpoints = [
	{ endpoint: "/", page: "Home" },
	{ endpoint: "/laundry/services", page: "Services" },
	{ endpoint: "/about", page: "About Us" },
	{ endpoint: "/how-it-works", page: "How It Works" },
	{ endpoint: "/faqs", page: "FAQs" },
	{ endpoint: "https://blog.laundro.co.uk", page: "Blog" },
];

export const servicesEndpoints = [
	{ endpoint: "/laundry/services/wash-dry-fold", page: "Wash, Dry & Fold" },
	{ endpoint: "/laundry/services/wash-dry-iron", page: "Wash, Dry & Iron" },
	{ endpoint: "/laundry/services/duvets", page: "Duvets" },
	{ endpoint: "/laundry/services/ironing", page: "Ironing" },
];
