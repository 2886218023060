import type { ServiceType } from "../../types";
import { Helmet } from "react-helmet";

const ServiceDetails = ({ service }: { service: ServiceType | undefined }) => {
	return (
		<div className="mt-6 w-full text-sm">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Service Details | Laundro</title>
				<link href={`https://laundro.co.uk/${service}`} />
			</Helmet>

			<div className="mb-6">
				<h3 className="text-xl font-bold">Service Overview</h3>
				<p>{service?.serviceDetails.serviceOverview}</p>
			</div>
			<div className="mb-6">
				<h3 className="text-xl font-bold">Ideal For</h3>
				<p>{service?.serviceDetails.suitableFor}</p>
			</div>
			<div className="mb-6">
				<h3 className="text-xl font-bold">Please Exclude</h3>
				<ul>
					{service?.serviceDetails.doNotInclude
						.split(".")
						.map((bulletpoint) => {
							return (
								bulletpoint !== "" && (
									<li key={bulletpoint.trim()} className="">
										✧ {bulletpoint}
									</li>
								)
							);
						})}
				</ul>
			</div>
			<div className="mb-6">
				<h3 className="text-xl font-bold">Get Ready for Collection</h3>
				<p>{service?.serviceDetails.prepareForCollection}</p>
			</div>
			<div className="mb-6">
				<h3 className="text-xl font-bold">Delivery With a Touch of Care</h3>
				<p>{service?.serviceDetails.receivingTheItemsBack}</p>
			</div>
		</div>
	);
};

export default ServiceDetails;
