import { FaShoppingBasket } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useStateContext } from "../context/ContextProvider";

const ShoppingCart = () => {
	const context = useStateContext();

	if (!context) {
		return null;
	}

	const { basket } = context;
	const totalItems = basket.reduce((total, item) => total + item.quantity, 0);

	return (
		<Link to="/checkout" className="btn btn-ghost hover:bg-transparent">
			<div className="indicator">
				<FaShoppingBasket size={24} className="fill-primary" />

				{totalItems > 0 && (
					<span className="badge badge-sm indicator-item bg-transparent border-none text-accent">
						{totalItems}
					</span>
				)}
			</div>
		</Link>
	);
};

export default ShoppingCart;
