import type React from "react";

interface StepCardProps {
	stepNumber: number;
	title: string;
	description: string;
	icon: React.ReactNode;
}

const StepCard: React.FC<StepCardProps> = ({
	stepNumber,
	title,
	description,
	icon,
}) => {
	return (
		<div className="flex flex-col items-center p-6">
			<div className="text-4xl mb-4">{icon}</div>
			<h2 className="text-2xl font-bold mb-2">{`Step ${stepNumber}: ${title}`}</h2>
			<p className="text-center text-gray-600">{description}</p>
		</div>
	);
};

export default StepCard;
