import { useState } from "react";
import PlusIconSVG from "../../assets/SVG/PlusIconSVG";
import WasherIconSVG from "../../assets/SVG/WasherIconSVG";
import { useStateContext } from "../../context/ContextProvider";
import type { ServiceOption } from "../../types";
import { addToBasket } from "../../utils/addToBasket";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import { Button } from "../ui/button";

interface ServicePriceCardProps {
	option?: ServiceOption;
}

const ServicePriceCard = ({ option }: ServicePriceCardProps) => {
	const context = useStateContext();
	const device = useDeviceDetection();
	const [showTickAnimation, setShowTickAnimation] = useState(false);

	if (!context) {
		return null;
	}

	const { basket, setBasket, serviceTypesData } = context;

	const basketCheckerForScentBoosters = basket.some(
		(item) => item.title.includes("6kg") || item.title.includes("9kg"),
	);

	const handleAddToBasket = () => {
		if (option) {
			addToBasket(
				basket,
				setBasket,
				option,
				serviceTypesData,
				setShowTickAnimation,
			);
		}
	};

	return (
		<div className="w-full bg-base-300 flex justify-between items-center gap-4 px-4 py-6 sm:px-10 rounded-lg shadow-lg">
			<div className="flex gap-4">
				<div className="justify-center items-center">
					<WasherIconSVG />
				</div>
				<div className="flex flex-col justify-center items-start">
					<h1 className="text-lg sm:text-xl">
						{device === "Mobile" ? option?.title.split("-")[1] : option?.title}
					</h1>
				</div>
			</div>
			<div className="flex justify-center items-center gap-4">
				<p className="font-bold text-md sm:text-lg">
					£{option?.price?.toFixed(2)}
				</p>
				{option?.title.includes("Scent Boosters") &&
				!basketCheckerForScentBoosters ? (
					<div
						className="tooltip tooltip-left"
						data-tip="Please add a wash option first"
					>
						<Button className="btn btn-primary" disabled>
							<PlusIconSVG />
						</Button>
					</div>
				) : (
					<Button
						className="btn btn-primary disabled:btn-success"
						onClick={handleAddToBasket}
						disabled={showTickAnimation}
					>
						{showTickAnimation ? (
							<span className="animate-pulse text-lg font-bold text-white">
								&#10003;
							</span>
						) : (
							<PlusIconSVG />
						)}
					</Button>
				)}
			</div>
		</div>
	);
};

export default ServicePriceCard;
