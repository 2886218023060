import type React from "react";

const NotFound: React.FC = () => {
	return (
		<div className="p-10 flex flex-col justify-center items-center items-center justify-center h-[60vh] w-screen">
			<h1 className="text-4xl font-bold text-red-600 mb-4">404 - Not Found</h1>
			<p className="text-lg text-gray-700">
				The page you are looking for does not exist.
			</p>
		</div>
	);
};

export default NotFound;
