import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AnimatedBubbles } from "@/components/AnimatedBubbles";
import WasherHomepageIllustrationSVG from "@/assets/SVG/WasherHomepageIllustrationSVG";

const Home = () => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta
					name="description"
					content="Laundro | The online laundrette | We offer free collection and delivery of your laundry for Washing, Drying and Ironing services!"
				/>
				<title>Laundry delivery | Online laundrette | Laundro</title>
				<link rel="canonical" href="https://laundro.co.uk/" />
				<meta
					property="og:title"
					content="Laundro - Laundry delivery service"
				/>
				<meta
					property="og:description"
					content="Free collection and delivery for washing, drying, and ironing services."
				/>
				<meta property="og:url" content="https://laundro.co.uk/" />
			</Helmet>

			<div className="w-screen min-h-screen p-10 flex flex-col lg:flex-row justify-center items-center bg-white text-white overflow-hidden">
				<AnimatedBubbles />

				<div className="container px-4 md:px-6 flex flex-col gap-4 space-y-4 text-center lg:text-left lg:w-1/2">
					<h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl text-gray-800">
						Laundry,{" "}
						<span className="text-accent inline-block">Revolutionised</span>.
					</h1>
					<p className="mx-auto lg:mx-0 max-w-[600px] text-gray-400 md:text-xl">
						{`Experience the future of clean. Schedule a pickup, we'll work our
						magic, and deliver freshness to your doorstep.`}
					</p>
					<div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
						<Link to="/laundry/services" className="btn btn-primary">
							Schedule Collection
						</Link>
						<Link to="/about" className="btn btn-outline text-primary">
							Learn More
						</Link>
					</div>
				</div>

				<div className="w-full lg:w-auto mt-10 lg:mt-0">
					<WasherHomepageIllustrationSVG className="w-full h-auto hidden md:flex" />
				</div>
			</div>
		</>
	);
};

export default Home;
