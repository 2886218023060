import CheckoutSteps from "@/components/Checkout/CheckoutSteps";
import OrderSummaryItems from "../components/Checkout/OrderSummaryItems";
import OrderSummaryPrices from "../components/Checkout/OrderSummaryPrices";
import { useStateContext } from "@/context/ContextProvider";
import { Link } from "react-router-dom";
import NothingHereSVG from "@/assets/SVG/NothingHereSVG";

const Checkout = () => {
	const context = useStateContext();

	return context?.basket?.length ? (
		<div className="w-full">
			<CheckoutSteps />

			<div className="grid grid-cols-1 md:grid-cols-2 sm:px-2 w-full lg:px-10 sm:gap-10">
				<OrderSummaryItems />
				<OrderSummaryPrices />
			</div>
		</div>
	) : (
		<div className="w-full h-full mt-8 rounded-lg p-10 flex flex-col justify-center items-center">
			<h1 className="text-2xl md:text-4xl font-bold md:font-black mb">
				Your 🧺 is empty!
			</h1>
			<NothingHereSVG className="w-full h-full md:w-1/3 md:h-1/3" />
			<Link className="btn btn-primary" to={"/laundry/services"}>
				Go Shopping
			</Link>
		</div>
	);
};

export default Checkout;
