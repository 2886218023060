import { useState } from "react";
import { loginUser } from "../utils/api";
import { useStateContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import { toastNotification } from "../utils/toastNotification";
import PasswordHider from "../components/PasswordHider";
import Register from "../components/Login/Register";

import validator from "validator";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";

const Login = () => {
	const {
		loggedInUser,
		setLoggedInUser,
		setOTP,
		setPasswordResetEmail,
		passwordResetEmail,
	} = useStateContext()!;

	const [selectedTab, setSelectedTab] = useState("Login");
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");

	const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const navigate = useNavigate();

	const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const ariaLabel = e.target.getAttribute("aria-label") || "";
		setSelectedTab(ariaLabel);
	};

	const handleLoginUser = async () => {
		try {
			if (userEmail && userPassword) {
				const user = await loginUser(
					userEmail.toLocaleLowerCase().trim(),
					userPassword,
				);

				localStorage.setItem("loggedInUserInfo", JSON.stringify(user));

				const storedUserInfo = localStorage.getItem("loggedInUserInfo")!;
				setLoggedInUser(JSON.parse(storedUserInfo));

				user.address ? navigate("/laundry/services") : navigate("/profile");
				window.location.reload();
			} else {
				toastNotification(
					"Please enter your email and password",
					"bg-error text-base-100",
				);
			}
		} catch (error) {
			if (!userPassword)
				toastNotification("Please enter a password", "bg-error text-base-100");
			if (userEmail && userPassword)
				toastNotification(
					"Email or password is incorrect",
					"bg-error text-base-100",
				);
		}
	};

	const handleResetPassword = async () => {
		if (passwordResetEmail !== "") {
			if (!validator.isEmail(passwordResetEmail)) {
				toastNotification("Invalid email address", "bg-error text-base-100");
				return;
			}

			const generatedOTP = Math.floor(100000 + Math.random() * 900000);

			localStorage.setItem("OTP", JSON.stringify(generatedOTP));
			setOTP(String(generatedOTP));

			localStorage.setItem(
				"passwordResetEmail",
				JSON.stringify(passwordResetEmail),
			);

			const data = {
				OTP: generatedOTP,
				fullName: loggedInUser?.fullName || "",
				email: passwordResetEmail,
			};

			try {
				const res = await emailjs.send(
					import.meta.env.VITE_EMAIL_JS_GMAIL_SERVICE_ID,
					import.meta.env.VITE_EMAIL_JS_PASSWORD_RESET_TEMPLATE_ID,
					data,
					import.meta.env.VITE_EMAIL_JS_GMAIL_PUBLIC_KEY,
				);

				if (res.status !== 200) {
					console.log(
						"%cLogin.tsx line:113 ERROR: ",
						"color: #26bfa5;",
						res.text,
					);
				}

				navigate("/password-reset");
			} catch (error) {
				console.log(
					"%chandleSendOrderConfirmationEmail.ts line:23 ERROR: ",
					"color: #26bfa5;",
					error,
				);
			}
		}
	};

	return (
		<div className="hero h-screen bg-base-100 sm:-mt-28">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Login/Register | Laundro</title>
				<meta
					name="description"
					content="Login or Register here to make full use of our platfrom and services."
				/>
				<link rel="canonical" href="https://laundro.co.uk/login" />
			</Helmet>

			<div className="hero-content grid grid-cols-1 lg:flex-row-reverse sm:grid-cols-2">
				<div className="text-center lg:text-left w-full sm:w-2/3">
					<h1 className="text-5xl font-bold mt-4">
						{selectedTab === "Login"
							? "Login now to Book"
							: "Register an Account"}
					</h1>

					<p className="py-6">
						{selectedTab === "Login"
							? "If you already have an account with us, you can login here and start booking our services!"
							: "You can create an account here, please ensure you fill out all of the required fields"}
					</p>
				</div>

				{/* TABS */}
				<div className="flex flex-col justify-center items-center w-full">
					<div className="join grid grid-cols-2 mb-2 w-full sm:w-2/3">
						<input
							className="join-item btn"
							type="radio"
							name="options"
							aria-label="Login"
							onChange={handleRadioButtonChange}
							checked={selectedTab === "Login"}
						/>
						<input
							className="join-item btn"
							type="radio"
							name="options"
							aria-label="Register"
							onChange={handleRadioButtonChange}
							checked={selectedTab === "Register"}
						/>
					</div>

					{selectedTab === "Login" ? (
						<div className="card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100">
							<div className="card-body">
								{!forgotPasswordActive && (
									<>
										<div className="form-control">
											<label className="label">
												<span className="label-text">Email</span>
											</label>
											<input
												type="email"
												placeholder="email"
												className="input input-bordered placeholder:opacity-40"
												onChange={(e) => setUserEmail(e.target.value)}
												required
											/>
										</div>
										<div className="form-control">
											<label className="label">
												<span className="label-text">Password</span>
											</label>
											<div className="flex">
												<input
													type={isPasswordVisible ? "text" : "password"}
													placeholder="password"
													className="input input-bordered flex-1 placeholder:opacity-40"
													onChange={(e) => setUserPassword(e.target.value)}
													required
												/>
												<PasswordHider
													isPasswordVisible={isPasswordVisible}
													setIsPasswordVisible={setIsPasswordVisible}
												/>
											</div>
											<label className="label">
												<button
													className="label-text-alt link link-hover"
													onClick={() => setForgotPasswordActive(true)}
												>
													Forgot password?
												</button>
											</label>
										</div>
										<div className="form-control mt-6">
											<button
												className="btn btn-primary"
												onClick={handleLoginUser}
											>
												Login
											</button>
										</div>
									</>
								)}

								{/* FORGOT PASSWORD */}
								{forgotPasswordActive && (
									<>
										<div onClick={() => setForgotPasswordActive(false)}>
											<p className="text-sm mb-4 cursor-pointer hover:text-primary">
												{"← Back To Login"}
											</p>
										</div>
										<div className="form-control">
											<label className="label">
												<span className="label-text">Email</span>
											</label>
											<input
												type="email"
												placeholder="email"
												className="input input-bordered placeholder:opacity-40"
												onChange={(e) => setPasswordResetEmail(e.target.value)}
												required
											/>
										</div>

										<div className="form-control mt-6">
											<button
												className="btn btn-primary"
												onClick={handleResetPassword}
											>
												Reset Password
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					) : (
						<Register
							isPasswordVisible={isPasswordVisible}
							setUserPassword={setUserPassword}
							setIsPasswordVisible={setIsPasswordVisible}
							userEmail={userEmail}
							userPassword={userPassword}
							setUserEmail={setUserEmail}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Login;
