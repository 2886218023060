import StepCard from "@/components/StepCard";
import { Helmet } from "react-helmet";
import { FaShoppingCart, FaTruck, FaSmile } from "react-icons/fa";

const HowITWorks = () => {
	return (
		<div className="container mx-auto p-6 my-24">
			<Helmet>
				<meta charSet="utf-8" />
				<title>How It Works | Laundro</title>
				<meta
					name="description"
					content={`It's as easy as 1-2-3. Place an order > We collect > We return your fresh laundry!`}
				/>
				<link rel="canonical" href="https://laundro.co.uk/how-it-works" />
			</Helmet>

			<h1 className="text-5xl font-bold text-center mb-10">{`It's As Easy As...`}</h1>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
				<StepCard
					stepNumber={1}
					title="Place an Order"
					description="Choose your services and date for collection, then place your order."
					icon={<FaShoppingCart />}
				/>
				<StepCard
					stepNumber={2}
					title="We Collect"
					description="Wait for one of our friendly drivers to come and collect your laundry."
					icon={<FaTruck />}
				/>
				<StepCard
					stepNumber={3}
					title="We Return"
					description="Your clothes will arrive back to you fresh and clean within the specified timeframe."
					icon={<FaSmile />}
				/>
			</div>
		</div>
	);
};

export default HowITWorks;
