import type { SVGProps } from "react";
import type { JSX } from "react/jsx-runtime";

const WasherHomepageIllustrationSVG = (
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="666.667"
		height="666.667"
		{...props}
	>
		<title>Washer Homepage Illustration</title>
		<defs>
			<clipPath id="a" clipPathUnits="userSpaceOnUse">
				<path d="M0 500h500V0H0Z" />
			</clipPath>
			<clipPath id="b" clipPathUnits="userSpaceOnUse">
				<path d="M173.322 359.051H321.69v-26.714H173.322Z" />
			</clipPath>
			<clipPath id="c" clipPathUnits="userSpaceOnUse">
				<path d="M190.923 443.849h111.972v-16.098H190.923Z" />
			</clipPath>
			<clipPath id="d" clipPathUnits="userSpaceOnUse">
				<path d="M184.089 402.205h124.075v-6.993H184.089Z" />
			</clipPath>
			<clipPath id="e" clipPathUnits="userSpaceOnUse">
				<path d="M176.558 427.383h139.02v-8.07h-139.02z" />
			</clipPath>
			<clipPath id="f" clipPathUnits="userSpaceOnUse">
				<path d="M181.408 419.261a8.488 8.488 0 1 1 0-16.975h128.804a8.488 8.488 0 0 1 0 16.975z" />
			</clipPath>
			<clipPath id="g" clipPathUnits="userSpaceOnUse">
				<path d="M171.979 415.872h151.76v-1.807h-151.76z" />
			</clipPath>
			<clipPath id="h" clipPathUnits="userSpaceOnUse">
				<path d="M171.979 407.649h151.76v-1.807h-151.76z" />
			</clipPath>
			<clipPath id="i" clipPathUnits="userSpaceOnUse">
				<path d="M0 500h500V0H0Z" />
			</clipPath>
			<clipPath id="j" clipPathUnits="userSpaceOnUse">
				<path d="M174.485 418.041h144.214v-15.755H174.485Z" />
			</clipPath>
			<clipPath id="k" clipPathUnits="userSpaceOnUse">
				<path d="M183.257 395.36a8.244 8.244 0 0 1-8.243-8.244 8.244 8.244 0 0 1 8.243-8.244h125.105a8.244 8.244 0 0 1 0 16.488z" />
			</clipPath>
			<clipPath id="l" clipPathUnits="userSpaceOnUse">
				<path d="M0 500h500V0H0Z" />
			</clipPath>
			<clipPath id="m" clipPathUnits="userSpaceOnUse">
				<path d="M176.313 393.883h140.293v-15.011H176.313Z" />
			</clipPath>
			<clipPath id="n" clipPathUnits="userSpaceOnUse">
				<path d="M67.533 428.127h358.752V239.594H67.533Z" />
			</clipPath>
			<clipPath id="o" clipPathUnits="userSpaceOnUse">
				<path d="M142.381 157.299h17.738v-11.432h-17.738z" />
			</clipPath>
			<clipPath id="p" clipPathUnits="userSpaceOnUse">
				<path d="M138.928 145.93h4.946v-5.959h-4.946z" />
			</clipPath>
		</defs>
		{/* <path fill="#feffff" d="M0 666.667h666.665V.002H0Z" /> */}
		<path fill="#6D0C75" d="M319.566 223.55h16.36v-37.458h-16.36z" />
		<g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
			<path
				fill="#6D0C75"
				d="M169.93 346.385c0-3.264 1.123-6.26 2.99-8.645v17.29a13.98 13.98 0 0 1-2.99-8.645M271.285 332.338h12.27v28.094h-12.27zm-15.805 0h12.27v28.094h-12.27zM183.977 360.432c-2.77 0-5.346-.813-7.522-2.197v-23.7a13.96 13.96 0 0 1 7.522-2.197h4.748v28.094ZM192.26 332.338h12.27v28.094h-12.27zm15.805 0h12.269v28.094h-12.269zm15.805 0h12.27v28.094h-12.27zm63.22 0h12.27v28.094h-12.27zM321.69 346.385c0 3.264-1.123 6.26-2.99 8.645v-17.29a13.98 13.98 0 0 1 2.99 8.645M307.643 360.432h-4.748v-28.094h4.748c2.77 0 5.346.812 7.522 2.197v23.7a13.97 13.97 0 0 1-7.522 2.197"
			/>
			<path
				fill="#dfe6ff"
				d="M172.92 355.03v-17.289a14.1 14.1 0 0 1 3.535-3.206v23.701a14.1 14.1 0 0 1-3.535-3.206M188.725 332.338h3.536v28.094h-3.536zm15.805 0h3.535v28.094h-3.535zm15.805 0h3.535v28.094h-3.535zm15.805 0h3.535v28.094h-3.535zm15.805 0h3.535v28.094h-3.535zm15.805 0h3.535v28.094h-3.535zm15.804 0h3.535v28.094h-3.535zm15.805 0h3.535v28.094h-3.535zM318.7 337.74v17.289a14.1 14.1 0 0 1-3.535 3.206v-23.7a14.1 14.1 0 0 1 3.535 3.205"
			/>
			<g clipPath="url(#b)" opacity="0.2">
				<path
					fill="#1c5a6c"
					d="M318.7 355.03a14.1 14.1 0 0 1-3.535 3.206c-.475.301-.972.571-1.482.815.134-.156.275-.307.402-.47a13.97 13.97 0 0 0 2.99-8.644c0-3.264-1.123-6.26-2.99-8.645a14.1 14.1 0 0 0-3.536-3.206 13.96 13.96 0 0 0-7.521-2.197H179.362c-2.166 0-4.21.503-6.04 1.381a13.96 13.96 0 0 1 10.655-4.932h123.666c2.77 0 5.346.813 7.522 2.197a14.1 14.1 0 0 1 3.535 3.206 13.97 13.97 0 0 1 2.99 8.644c0 3.264-1.123 6.26-2.99 8.645"
				/>
			</g>
			<path
				fill="#ffe19c"
				d="M241.195 427.751h9.23v17.071h-9.23zM188.725 436.286c0-1.983.845-3.804 2.25-5.253v10.506c-1.405-1.449-2.25-3.27-2.25-5.253M264.975 427.751h9.23v17.071h-9.23zm-11.89 0h9.231v17.071h-9.231zM199.293 444.822c-2.084 0-4.021-.494-5.658-1.335v-14.401c1.637-.841 3.574-1.335 5.658-1.335h3.572v17.071ZM205.524 427.751h9.231v17.071h-9.231zm11.89 0h9.23v17.071h-9.23zm11.89 0h9.231v17.071h-9.231zm47.561 0h9.231v17.071h-9.231zM302.895 436.286c0 1.983-.845 3.804-2.25 5.253v-10.506c1.405 1.449 2.25 3.27 2.25 5.253M292.327 444.822h-3.572v-17.071h3.572c2.084 0 4.021.494 5.658 1.335v14.401c-1.637.841-3.574 1.335-5.658 1.335"
			/>
			<path
				fill="#f9b674"
				d="M190.975 441.54v-10.506a10.2 10.2 0 0 1 2.66-1.947v14.401a10.2 10.2 0 0 1-2.66-1.948M202.865 427.751h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zm11.89 0h2.66v17.071h-2.66zM300.645 431.034v10.506a10.2 10.2 0 0 1-2.66 1.947v-14.401a10.2 10.2 0 0 1 2.66 1.948"
			/>
			<g clipPath="url(#c)" opacity="0.54">
				<path
					fill="#ffcc73"
					d="M300.645 441.54a10.2 10.2 0 0 1-2.66 1.948c-.263.135-.546.245-.824.362 1.372-1.44 2.198-3.238 2.198-5.195 0-1.984-.845-3.804-2.249-5.253a10.2 10.2 0 0 0-2.66-1.948c-1.637-.841-3.575-1.335-5.658-1.335h-93.034c-1.749 0-3.383.364-4.834.973l.051-.058a10.2 10.2 0 0 1 2.659-1.947c1.637-.841 3.575-1.335 5.659-1.335h93.034c2.084 0 4.021.494 5.658 1.335a10.2 10.2 0 0 1 2.66 1.947c1.405 1.45 2.25 3.27 2.25 5.253s-.845 3.804-2.25 5.253"
				/>
			</g>
			<path
				fill="#ffcc73"
				d="M315.772 360.432H175.849a9.22 9.22 0 1 0 0 18.44h139.923a9.22 9.22 0 0 0 0-18.44"
			/>
			<path
				fill="#eb9300"
				d="M320.482 377.568a9.17 9.17 0 0 0 1.208-4.552 9.22 9.22 0 0 0-9.22-9.22H172.546c-1.723 0-3.33.482-4.71 1.305a9.21 9.21 0 0 1 8.012-4.669h139.924a9.22 9.22 0 0 1 9.22 9.22c0 3.369-1.813 6.307-4.51 7.916"
			/>
			<path
				fill="#5d6ab3"
				d="M305.657 395.212H185.962a2.507 2.507 0 0 0-2.507 2.507v2.528a2.51 2.51 0 0 0 2.507 2.507h119.695a2.51 2.51 0 0 0 2.507-2.507v-2.528a2.507 2.507 0 0 0-2.507-2.507"
			/>
			<g clipPath="url(#d)" opacity="0.2">
				<path
					fill="#1c5a6c"
					d="M306.55 402.205a3.45 3.45 0 0 0 .633-1.991v-.595a3.47 3.47 0 0 0-3.473-3.473H185.948c-.686 0-1.321.205-1.859.548a3.46 3.46 0 0 1 2.84-1.483h117.763a3.473 3.473 0 0 1 3.473 3.473v.596a3.46 3.46 0 0 1-1.615 2.925"
				/>
			</g>
			<path
				fill="#009cf0"
				d="M311.359 419.313H180.261a4.22 4.22 0 0 0 0 8.438h131.098a4.22 4.22 0 0 0 0-8.438"
			/>
			<g clipPath="url(#e)" opacity="0.5">
				<path
					fill="#009cf0"
					d="M313.068 427.383a4.2 4.2 0 0 0 .516-1.982 4.22 4.22 0 0 0-4.219-4.219H178.267c-.61 0-1.186.135-1.709.368.711-1.326 2.093-2.237 3.703-2.237h131.098a4.215 4.215 0 0 1 1.709 8.07"
				/>
			</g>
			<path
				fill="#cb8100"
				d="M310.212 402.286H181.408a8.488 8.488 0 1 0 0 16.975h128.804a8.488 8.488 0 0 0 0-16.975"
			/>
		</g>
		<g clipPath="url(#f)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
			<path
				fill="#ffba16"
				d="M181.65 395.936h-10.225v37.006h10.225zm27.709 0h-10.225v37.006h10.225zm27.709 0h-10.225v37.006h10.225zm27.709 0h-10.225v37.006h10.225zm27.709 0h-10.225v37.006h10.225zm27.709 0H309.97v37.006h10.225z"
			/>
			<g clipPath="url(#g)" opacity="0.3">
				<path fill="#eb9300" d="M323.739 414.065h-151.76v1.807h151.76z" />
			</g>
			<g clipPath="url(#h)" opacity="0.3">
				<path fill="#eb9300" d="M323.739 405.842h-151.76v1.807h151.76z" />
			</g>
		</g>
		<g clipPath="url(#i)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
			<g clipPath="url(#j)" opacity="0.2">
				<path
					fill="#1c5a6c"
					d="M314.58 418.04a8.44 8.44 0 0 0 1.565-4.9c0-4.687-3.8-8.487-8.488-8.487H178.853c-1.6 0-3.091.451-4.369 1.22a8.47 8.47 0 0 1 6.923-3.588h128.804c4.688 0 8.488 3.8 8.488 8.488 0 3.087-1.654 5.782-4.119 7.267"
				/>
			</g>
			<path
				fill="#b9c5ff"
				d="M308.362 378.873H183.257a8.244 8.244 0 0 0 0 16.488h125.105a8.244 8.244 0 0 0 0-16.488"
			/>
		</g>
		<g
			fill="#6D0C75"
			clipPath="url(#k)"
			transform="matrix(1.33333 0 0 -1.33333 0 666.667)"
		>
			<path d="M187.424 395.36a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M197.704 380.315a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M211.815 387.79a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M223.122 395.36a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M233.401 380.315a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M247.512 387.79a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M258.82 395.36a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M269.098 380.315a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M283.21 387.79a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M294.516 395.36a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M304.796 380.315a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0M318.906 387.79a3.045 3.045 0 1 0-6.09 0 3.045 3.045 0 0 0 6.09 0" />
		</g>
		<g clipPath="url(#l)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
			<g clipPath="url(#m)">
				<path
					fill="#b9c5ff"
					d="M313.064 393.883a8.2 8.2 0 0 0 1.299-4.43 8.243 8.243 0 0 0-8.243-8.244H181.015a8.2 8.2 0 0 0-4.702 1.477 8.23 8.23 0 0 1 6.945-3.813h125.104a8.243 8.243 0 0 1 8.244 8.243 8.23 8.23 0 0 1-3.542 6.767"
				/>
			</g>
			<path fill="#5d6ab3" d="M134.914 61.881H67.937v1h66.977z" />
			<path
				fill="#5d6ab3"
				d="M157.82 102.852s-.144-2.003-.772-5.115a44 44 0 0 0-.312-1.464c-1.234-5.469-3.82-13.435-9.106-20.288q-.004-.005-.003-.006c-.291-.371-.58-.733-.886-1.099-1.917-2.311-4.164-4.464-6.797-6.32q-.393-.278-.804-.547c-4.209-2.786-9.357-4.81-15.65-5.516h-.003a29 29 0 0 0-1.198-.115h-.004a44 44 0 0 0-3.855-.162c-5.354 0-10.051.877-14.165 2.376a28 28 0 0 0-.875.327c-5.701 2.241-10.257 5.681-13.883 9.595q-.415.447-.809.901c-3.416 3.897-5.958 8.193-7.828 12.21q-.186.398-.363.793c-3.527 7.857-4.493 14.43-4.493 14.43Z"
			/>
			<path
				fill="#6D0C75"
				d="M92.653 102.852c-2.963-5.855-6.659-11.568-11.783-15.223q-.185.398-.362.793c4.827 3.486 8.343 8.888 11.167 14.43zM104.76 102.852a40.6 40.6 0 0 1-11.65-14.643c-1.67-3.551-3.676-8.935-3.602-13.691q-.416.447-.81.901c.147 4.062 1.57 8.367 2.91 11.568a41.45 41.45 0 0 0 11.753 15.865zM139.944 68.56q-.393-.279-.804-.548c-2.45 12.443 1.228 25.519 9.907 34.84h1.225c-8.814-9.083-12.628-21.953-10.328-34.292M128.447 69.335a131 131 0 0 0-4.956-6.838h-.003a29 29 0 0 0-1.199-.115 131 131 0 0 1 6.912 9.692c1.24 1.92 3.443 4.718 2.63 7.186-1.025 3.11-5.307 1.802-7.492 1.042-8.208-2.848-14.463-9.215-20.074-15.706-.294.103-.586.215-.874.327 2.533 2.931 5.154 5.787 8.043 8.373 3.251 2.913 6.856 5.478 10.893 7.177 2.707 1.137 7.651 3.098 9.848.021 2.454-3.431-1.92-8.488-3.728-11.159M157.048 97.736a46 46 0 0 0-.312-1.464c-5.584-5.256-8.868-12.681-9.106-20.288q-.004-.005-.003-.006c-.292-.371-.58-.733-.886-1.098-.053 8.637 3.69 17.175 10.307 22.856M140.088 102.852a133 133 0 0 0-5.148-4.576 139 139 0 0 0-13.367-9.96c-2.253-1.476-4.559-3.099-7.039-4.174-1.926-.833-4.226-1.228-6.022.115-3.802 2.836-1.755 8.564-.065 11.998a25.6 25.6 0 0 0 4.683 6.597h1.257a24.8 24.8 0 0 1-5.719-8.231c-.934-2.197-1.882-4.865-1.202-7.254.972-3.416 4.317-3.546 7.08-2.253 4.208 1.965 8.161 4.921 11.907 7.637a136 136 0 0 1 12.333 10.101Z"
			/>
			<path
				fill="#181709"
				d="M157.82 102.852c0-1.291-18.313-2.337-40.903-2.337s-40.903 1.046-40.903 2.337 18.313 2.337 40.903 2.337 40.903-1.046 40.903-2.337"
			/>
			<path
				fill="#009cf0"
				d="m128.226 194.009.074.021c1.75.49 3.191-.68 2.366-2.917-1.078-2.929-2.514-4.089-4.7-2.991-1.376.69-.197 5.149 2.26 5.887m-21.54-6.06c-2.448.024-6.338 4.593-8.158 7.129-1.826 2.548-.364 4.279 1.388 3.79l.074-.022c2.457-.738 9.867-10.926 6.696-10.897m31.078.506c-2.452-2.941-4.221-13.81-8.91-15.56-3.226-1.206-4.936 3.457 1.316 9.342 4.027 3.792 5.82 8.325 6.585 11.072-1.103 4.799-2.543 9.725-4.406 14.208-3.608-3.335-9.392-8.064-11.043-5.804-1.798 2.462 3.583 10.224 6.594 14.203-1.397 2.035-2.951 3.807-4.677 5.214-2.819 2.304-6.093 3.624-9.893 3.524-2.739-.072-5.252-1.067-7.515-2.745 3.22-6.369 7.964-17.132 4.639-19.608-3.085-2.299-7.558 6.613-10.356 13.263-3.938-6.082-6.585-14.416-7.73-22.456.769-1.441 2.104-3.114 4.405-4.768 6.099-4.382 13.34-13.239 9.718-15.208-3.619-1.97-7.4 3.234-10.099 7.873-1.385 2.382-2.938 4.305-4.626 5.575a52 52 0 0 1-.01-4.106c.959-25.287 25.649-25.28 25.649-25.28s24.722 5.698 20.359 31.261"
			/>
			<path
				fill="#181709"
				d="M114.716 194.775c2.152-11.006 1.472-22.359.655-33.469-.886-12.033-2.094-24.085-1.623-36.166.264-6.764 1.078-13.502 2.7-20.08.15-.605 1.084-.349.935.258-5.862 23.767-.447 47.949-.284 71.968.041 5.946-.306 11.903-1.449 17.747-.119.611-1.053.352-.934-.258"
			/>
			<path
				fill="#009cf0"
				d="m92.674 155.939.047.061c1.125 1.427 2.978 1.332 3.631-.96.857-3.002.381-4.786-2.032-5.189-1.518-.254-3.195 4.043-1.646 6.088m-13.822-17.597c-1.991-1.424-7.827-.029-10.792.947-2.978.98-2.818 3.24-1.115 3.879q.036.012.073.026c2.42.853 14.412-3.005 11.834-4.852m24.8 18.736c-.245-3.821 4.735-13.642 1.981-17.82-1.895-2.877-6.025-.119-4.447 8.32 1.016 5.438-.209 10.156-1.211 12.826-3.721 3.225-7.789 6.354-11.937 8.875-.947-4.82-2.829-12.051-5.497-11.199-2.903.928-3.135 10.37-3.05 15.359-2.329.82-4.628 1.334-6.851 1.453-3.636.197-7.059-.667-10.068-2.989-2.17-1.674-3.613-3.959-4.45-6.648 6.356-3.245 16.534-9.14 15.309-13.101-1.135-3.675-10.004.884-16.185 4.605.406-7.234 3.184-15.526 7-22.695 1.471-.71 3.536-1.273 6.37-1.252 7.509.057 18.581-2.825 16.817-6.55-1.761-3.726-7.883-1.753-12.799.401-2.524 1.108-4.912 1.744-7.024 1.775a52 52 0 0 1 2.414-3.322c15.687-19.856 35.622-5.29 35.622-5.29s16.605 19.181-1.994 37.252"
			/>
			<path
				fill="#181709"
				d="M81.27 148.627c5.793-5.332 11.535-10.862 16.217-17.222 3.577-4.86 6.338-10.197 8.837-15.673 1.786-3.913 3.484-7.864 5.228-11.795.252-.569 1.087-.077.836.489-2.867 6.463-5.537 13.056-8.902 19.284-2.961 5.483-6.65 10.464-10.851 15.059-3.377 3.693-6.999 7.156-10.68 10.543-.458.422-1.145-.262-.685-.685"
			/>
			<path
				fill="#009cf0"
				d="M136.66 145.221c-2.41.419-2.873 2.206-1.996 5.202.669 2.288 2.523 2.37 3.638.936l.047-.062c1.534-2.056-.173-6.341-1.689-6.076m27.255-6.848.073-.026c1.699-.651 1.843-2.912-1.142-3.872-2.971-.954-8.817-2.309-10.798-.871-2.565 1.865 9.453 5.639 11.867 4.769m-38.79-23.097s19.833-14.706 35.659 5.041a53 53 0 0 1 2.437 3.305c-2.113-.016-4.505-.636-7.037-1.726-4.93-2.12-11.066-4.05-12.801-.312-1.739 3.737 9.353 6.543 16.862 6.433 2.833-.042 4.902.508 6.378 1.207 3.866 7.142 6.702 15.414 7.158 22.646-6.206-3.678-15.106-8.175-16.216-4.492-1.198 3.969 9.022 9.793 15.4 12.993-.819 2.696-2.245 4.991-4.403 6.68-2.993 2.343-6.41 3.231-10.047 3.058-2.224-.103-4.527-.601-6.862-1.404.05-4.99-.247-14.43-3.157-15.338-2.673-.833-4.505 6.411-5.418 11.238-4.165-2.493-8.256-5.593-11.999-8.793-1.021-2.662-2.279-7.372-1.301-12.816 1.519-8.45-2.63-11.179-4.504-8.289-2.725 4.197 2.324 13.983 2.105 17.806-18.725-17.941-2.254-37.237-2.254-37.237"
			/>
			<path
				fill="#181709"
				d="M149.019 144.594c-5.698-5.166-11.49-10.365-16.407-16.302-3.585-4.329-6.443-9.112-8.931-14.141-1.89-3.821-3.632-7.715-5.389-11.598-.255-.564.58-1.057.837-.489 2.757 6.094 5.42 12.296 8.865 18.044 3.062 5.11 6.914 9.603 11.106 13.816 3.425 3.441 7.007 6.724 10.604 9.985.463.419-.224 1.103-.685.685"
			/>
			<path
				fill="#ffb3df"
				d="m363.07 53.259-230.105.026.028 242.163 230.105-.026z"
			/>
			<path
				fill="#ff70c8"
				d="m350.42 295.423-.022-195.126c-.003-17.484-14.177-31.655-31.661-31.653l-185.77.021-.001-15.38 230.104-.026.028 242.163z"
			/>
			<path
				fill="#ffb3df"
				d="m363.103 334.108-230.105.026-.004-38.685 230.105-.027z"
			/>
			<path
				fill="#ff70c8"
				d="m356.572 334.108-.003-25.791a8.81 8.81 0 0 0-8.812-8.81l-214.763.025-.001-4.084 230.105-.026.005 38.685z"
			/>
			<path
				fill="#ff42ae"
				d="m363.098 292.742-230.105.026v2.68l230.105-.027ZM363.073 77.877l-230.105.026v2.68l230.105-.027Z"
			/>
			<path
				fill="#ff70c8"
				d="M345.249 175.85c-.006-50.778-41.174-91.936-91.952-91.931s-91.937 41.174-91.931 91.952 41.174 91.937 91.952 91.931 91.937-41.174 91.931-91.952"
			/>
			<path
				fill="#ff8f07"
				d="M342.385 180.372c-.006-52.109-42.254-94.348-94.364-94.342-52.109.006-94.347 42.254-94.341 94.364.006 52.109 42.254 94.348 94.363 94.342s94.348-42.254 94.342-94.364"
			/>
			<path
				fill="#F7860D"
				d="M339.974 180.372c-.006-50.778-41.174-91.937-91.952-91.931s-91.937 41.174-91.931 91.952 41.174 91.937 91.952 91.931 91.937-41.174 91.931-91.952"
			/>
			<path
				fill="#6D0C75"
				d="M324.215 180.374c-.005-42.074-34.117-76.178-76.191-76.173s-76.178 34.117-76.173 76.191 34.117 76.178 76.191 76.173 76.178-34.117 76.173-76.191"
			/>
			<path
				fill="#ff42ae"
				d="M262.534 314.776c-.001-8.001-6.488-14.485-14.488-14.485-8 .001-14.485 6.488-14.484 14.488 0 8.001 6.487 14.486 14.487 14.485s14.486-6.487 14.485-14.488"
			/>
			<path
				fill="#F7860D"
				d="M260.726 314.777c-.001-7.002-5.678-12.678-12.68-12.677s-12.677 5.678-12.676 12.68 5.677 12.677 12.679 12.676 12.678-5.677 12.677-12.679"
			/>
			<path
				fill="none"
				stroke="#ff83ca"
				strokeMiterlimit="10"
				d="m210.284 146.913 42.8 84.9M259.917 194.597l10.022 19.879M227.138 129.576l25.652 50.885M253.307 126.077l29.599 58.715"
			/>
			{/* <path
				fill="#feffff"
				d="m350.187 303.21-34.666.004.002 23.111 34.667-.004z"
			/> */}
			<path
				fill="#ff42ae"
				d="m348.235 305.15-30.763.004.002 19.231 30.764-.004zM188.326 305.169l-47.729.006.002 19.231 47.729-.006zM295.677 323.422l-25.288.003v.966l25.288-.003ZM295.677 318.856l-25.288.003v.966l25.288-.003ZM295.676 314.29l-25.288.003v.966l25.288-.003ZM295.676 309.723l-25.288.003v.966l25.288-.003ZM295.675 305.157l-25.288.003v.966l25.288-.003ZM200.42 323.433l25.288-.003v.966l-25.288.003ZM200.42 318.867l25.288-.003v.966l-25.288.003ZM200.42 314.3l25.288-.003v.966l-25.288.003ZM200.42 309.734l25.288-.003v.966l-25.288.003ZM200.418 305.168l25.288-.003v.966l-25.288.003Z"
			/>
			<path
				fill="#b6c2ec"
				d="M230.78 215.84a8.903 8.903 0 1 0-17.805.001 8.903 8.903 0 0 0 17.805-.001M289.695 140.539a4.761 4.761 0 1 0-9.522 0 4.761 4.761 0 0 0 9.522 0"
			/>
			<path
				fill="#5d6ab3"
				d="M350.653 40.22h-69.977v1h69.977zm-72.416 0h-6.582v1h6.582z"
			/>
			<path
				fill="#ffba16"
				d="M294.658 141.946c3.415-.357 6.349-2.822 9.771-3.114 5.283-.452 9.389 4.17 13.577 7.423 8.97 6.967 21.943 8.339 32.171 3.402 3.775-1.823 7.602-5.686 6.186-9.632-1.481-4.129-7.912-5.51-8.129-9.892-.199-4.035 5.379-7.054 4.29-10.945-.646-2.305-3.425-3.346-5.814-3.185-2.388.161-4.651 1.154-7.031 1.414-6.769.741-12.573-4.349-18.702-7.316-6.883-3.332-14.992-4.054-22.355-1.99-6.585 1.846-17.391 7.483-17.607 15.434-.198 7.307 4.795 19.326 13.643 18.401"
			/>
			<path
				fill="#ffa300"
				d="M309.953 128.022s14.386 10.952 18.822 10.952-15.376-7.282-15.376-11.455-.616-7.712-2.461-5.573c-1.846 2.138-.985 6.076-.985 6.076M390.094 143.356c4.442 2.299 10.271 3.734 14.129.551 3.474-2.866 3.904-8.42 7.584-11.017 1.878-1.326 4.265-1.622 6.467-2.282s4.509-1.981 5.059-4.213c.588-2.382-1.179-4.837-3.402-5.876s-4.776-1.006-7.229-.954l-22.578.483c-4.371.094-19.487 1.222-16.806 9.064 1.005 2.94 5.199 5.938 7.469 7.913a47.4 47.4 0 0 0 9.307 6.331"
			/>
			<path
				fill="#cb8100"
				d="M413.861 128.294c-1.327-.027-2.736-.004-3.858.705-.932.589-1.531 1.567-2.173 2.464-.642.896-1.433 1.789-2.504 2.048-1.072.259-2.419-.449-2.418-1.551.002-1.023 1.036-1.687 1.916-2.209.88-.523 1.845-1.372 1.6-2.365-.229-.927-1.431-1.413-1.534-2.363-.091-.841.737-1.48 1.513-1.818 1.87-.816 3.989-.963 6.004-.644 1.749.277 5.622 1.072 6.51 2.904 1.258 2.597-3.685 2.857-5.056 2.829"
			/>
			<path
				fill="#ffcc73"
				d="M339.772 132.19c2.306.453 4.8.337 6.845 1.495 2.377 1.346 3.564 4.083 4.372 6.693s1.457 5.394 3.267 7.44c1.686 1.905 4.311 2.936 6.842 2.687 2.532-.248 4.906-1.769 6.19-3.966 1.75-2.995 1.665-7.191 4.48-9.22 2.251-1.621 5.381-1.068 7.98-.099s5.218 2.31 7.981 2.053c2.575-.239 4.861-1.885 6.417-3.951s2.482-4.531 3.301-6.985c.793-2.377 1.477-5.11.225-7.281-1.405-2.437-4.579-3.069-7.37-3.422a391 391 0 0 0-54.526-3.058c-4.589.063-7.71 1.198-6.863 6.441.897 5.554 5.352 10.091 10.859 11.173"
			/>
			<path
				fill="#ffe19c"
				d="M342.561 128.294c1.901 1.073 4.117 1.424 6.142 2.239 2.025.814 4.019 2.35 4.318 4.512.108.78-.015 1.589.186 2.351.202.761.917 1.494 1.683 1.308.539-.131.893-.682.968-1.232.076-.55-.069-1.104-.214-1.64-.752-2.797-1.717-5.882-4.236-7.312-1.433-.813-3.289-1.031-4.291-2.339-.757-.987-.812-2.35-1.437-3.426-1.078-1.855-4.473-2.473-5.788-.536-1.477 2.177.833 5.038 2.669 6.075M391.846 122.854s-4.553 7.322-8.552 8.429c-4 1.108 8.552-8.429 8.552-8.429M331.13 83.214c-7.092.843-14.283.095-21.405.625s-14.565 2.594-19.356 7.891c-3.436 3.8-5.249 10.01-1.711 14.358 2.517 3.094 6.44 4.409 10.198 5.341 20.373 5.052 41.667 2.946 62.505 4.977 18.739 1.826 37.447 7.013 56.132 4.645 9.287-1.178 16.83-17.184 8.243-24.114-.935-.755-2.155-1.68-1.877-2.888.089-.387.33-.712.564-1.024 2.881-3.841 6.073-6.965 5.949-12.247-.062-2.638-1.297-5.062-2.01-7.591-.854-3.035-1.231-6.706-3.813-8.309-2.158-1.34-5.02-.51-6.976 1.143-1.955 1.653-3.27 3.982-4.787 6.087a32.7 32.7 0 0 1-10.648 9.478c-5.277 2.922-11.583 3.12-17.431 3.767-6.523.722-13.069 1.18-19.618 1.555-13.121.753-26.271 1.165-39.343 2.667"
			/>
			<path
				fill="none"
				stroke="#ffddb5"
				strokeMiterlimit="10"
				d="M423.86 94.049c-3.957 5.564-11.315 7.638-15.167 8.176-9.072 1.266-17.976-1.439-26.798-3.13-11.649-2.233-58.681.487-70.378-.157-2.604-.144-5.454-.395-7.326-2.315"
			/>
			<path
				fill="#5d6ab3"
				d="m361.914 41.96-55.964-.398c-2.615-.019-5.28-.03-7.777.763-6.531 2.077-9.606 8.561-12.57 14.21-2.696 5.139-3.664 10.929-4.599 16.613l-1.092 6.641c-.348 2.116-.68 4.363.193 6.334 1.063 2.399 3.673 3.817 6.253 4.624 4.302 1.346 8.902 1.472 13.427 1.507 10.82.084 21.638-.289 32.451-.661 22.862-.787 45.852-1.589 68.252-6.053 5.827-1.162 11.583-3.601 14.217-9.424.581-1.284.98-2.67 1.783-3.823 1.331-1.911 3.594-2.938 5.085-4.722 1.397-1.672 2.006-3.866 2.503-6 1.024-4.393 1.585-9.515-1.269-12.974-2.741-3.322-7.527-3.776-11.791-3.895a317 317 0 0 0-10.595-.118c-2.766.015-4.926-.793-7.587-1.109-7.147-.849-14.646-.561-21.83-.48"
			/>
			<path
				fill="none"
				stroke="#f3f5ff"
				strokeMiterlimit="10"
				d="M298.349 79.111c3.936-6.441 11.447-9.756 18.795-11.2 10.388-2.042 21.091-1.099 31.645-.304 16.502 1.241 33.09.659 49.565 1.988 6.092.492 15.221 3.162 21.202 1.116"
			/>
			<path
				fill="none"
				stroke="#181709"
				strokeMiterlimit="10"
				d="m286.395 45.857-16.976 79.475M422.622 45.857l16.975 79.475M304.276 45.382l-34.856 79.949M280.881 71.671l23.395 53.66M326.83 45.382l-34.856 79.949M291.973 45.382l34.856 79.949M349.383 45.382l-34.856 79.949M314.527 45.382l34.856 79.949M371.937 45.382l-34.856 79.949M337.08 45.382l34.856 79.949M394.49 45.382l-34.856 79.949M359.634 45.382l34.856 79.949M417.044 45.382l-34.856 79.949M382.187 45.382l34.856 79.949M428.135 71.671l-23.395 53.66M404.74 45.382l34.856 79.949"
			/>
			<path
				fill="#181709"
				d="M423.851 40.22H285.166v5.637h138.685zm21.023 85.111H264.143v3.819h180.731z"
			/>
			<path
				fill="#ffba16"
				d="m284.031 118.338 22.483.797 5.124 11.999c.266.623.525 1.369.164 1.943-.357.567-1.131.66-1.8.696-3.451.184-7.055.692-10.507.517-2.338-.118-5.884 1.184-7.883.036-4.896-2.81-6.958-10.318-7.581-15.988"
			/>
			<path
				fill="#ffa300"
				d="m283.708 122.034 25.007-.606-1.383-9.176h-25.007Z"
			/>
			<path
				fill="#6d1f10"
				d="M288.961 117.144a2.114 2.114 0 1 0-4.228 0 2.114 2.114 0 0 0 4.228 0"
			/>
			<path
				fill="#ffba16"
				d="M286.43 117.87a.324.324 0 1 0-.648 0 .324.324 0 0 0 .648 0M287.914 117.87a.324.324 0 1 0-.648 0 .324.324 0 0 0 .648 0M286.43 116.417a.324.324 0 1 0-.648 0 .324.324 0 0 0 .648 0M287.914 116.417a.324.324 0 1 0-.648 0 .324.324 0 0 0 .648 0"
			/>
			<g fill="#b6c2ec" clipPath="url(#n)" opacity="0.6">
				<path d="M140.492 383.529c0-8.345-6.765-15.11-15.11-15.11s-15.109 6.765-15.109 15.11 6.764 15.11 15.109 15.11 15.11-6.765 15.11-15.11M155.907 418.04c0-5.57-4.516-10.086-10.086-10.086s-10.087 4.516-10.087 10.086 4.516 10.086 10.087 10.086 10.086-4.515 10.086-10.086M83.3 313.275a7.883 7.883 0 0 0-7.883-7.883 7.883 7.883 0 0 0-7.883 7.883 7.883 7.883 0 0 0 7.883 7.883 7.883 7.883 0 0 0 7.883-7.883M343.394 268.067c0-9.359-7.587-16.946-16.946-16.946s-16.946 7.587-16.946 16.946 7.587 16.946 16.946 16.946 16.946-7.587 16.946-16.946M426.285 320.706c0-14.425-11.694-26.118-26.119-26.118s-26.118 11.693-26.118 26.118 11.694 26.119 26.118 26.119c14.425 0 26.119-11.694 26.119-26.119M342.867 393.862a6.14 6.14 0 0 0-6.141-6.141 6.14 6.14 0 0 0-6.141 6.141 6.14 6.14 0 0 0 6.141 6.141 6.14 6.14 0 0 0 6.141-6.141M364.658 255.744a7.005 7.005 0 1 0-14.01 0 7.005 7.005 0 0 0 14.01 0M92.34 298.092a3.642 3.642 0 1 0-7.284 0 3.642 3.642 0 0 0 7.284 0M109.376 251.284c0-6.456-5.234-11.69-11.69-11.69s-11.689 5.234-11.689 11.69 5.233 11.69 11.689 11.69 11.69-5.234 11.69-11.69M378.235 387.21c0-9.066-7.349-16.415-16.415-16.415s-16.415 7.349-16.415 16.415 7.349 16.415 16.415 16.415 16.415-7.349 16.415-16.415" />
			</g>
			<path
				fill="#5d6ab3"
				d="M176.913 40.657h-66.977v1h66.977zm-72.416 0H92.915v1h11.582z"
			/>
			<path
				fill="#ffba16"
				d="M164.089 132.364s21.83-25.781 8.884-87.623a5.76 5.76 0 0 0-5.659-4.583l-46.051.158a5.76 5.76 0 0 0-5.728 6.143c.496 7.517 1.705 12.068 1.719 16.273.043 12.329-12.044 39.003 21.062 69.721z"
			/>
			<path
				fill="#eb9300"
				d="m138.315 132.453 4.442-.015c-33.106-30.718-21.018-57.392-21.061-69.721-.014-4.206-1.223-8.756-1.72-16.273a5.763 5.763 0 0 1 5.729-6.143l-4.443.015a5.763 5.763 0 0 0-5.728 6.143c.496 7.517 1.705 12.068 1.72 16.273.042 12.33-12.045 39.003 21.061 69.721"
			/>
			<path
				fill="#eb9300"
				d="M134.29 65.322c1.457 2.467.615 21.24-5.248 32.291-.846 1.593-3.301 1.361-4.445-.034-4.152-5.065-4.886-21.003 5.779-32.243.9-.95 3.129-.794 3.914-.014"
			/>
			<path
				fill="#eb9300"
				d="M148.466 116.008s9.321 1.095 19.71-5.184a6.87 6.87 0 0 0 3.295-5.457c.435-7.036.947-24.185-2.388-44.624a12.07 12.07 0 0 0-8.818-9.734c-4.119-1.088-9.886-1.709-17.286-.403a6.595 6.595 0 0 0-5.406 7.214c.939 8.628 1.315 25.902-6.882 44.09-1.19 2.64-.607 5.769 1.5 7.756 2.684 2.533 7.568 5.389 16.275 6.342"
			/>
			<path
				fill="#eb9300"
				d="M149.613 113.996s8.507 1.018 17.988-4.816c1.76-1.083 2.88-2.98 3.007-5.07.398-6.538.863-22.472-2.181-41.463-.7-4.364-3.85-7.915-8.049-9.045-3.759-1.011-9.023-1.588-15.777-.376-3.122.561-5.277 3.494-4.934 6.703.858 8.017 1.203 24.068-6.278 40.967-1.086 2.453-.554 5.36 1.369 7.207 2.451 2.353 6.908 5.007 14.855 5.893"
			/>
			<path
				fill="#ffba16"
				d="m164.089 132.364.016 4.501-25.774.089-.015-4.501Z"
			/>
			<path fill="#eb9300" d="m164.089 132.364.003.815-25.774.089-.002-.815Z" />
			<path
				fill="#eb9300"
				d="m142.758 132.438.016 4.501-4.443.016-.015-4.502Z"
			/>
			<path
				fill="#ffe19c"
				d="m160.116 145.867.029 8.243a3.14 3.14 0 0 1-3.129 3.149l-11.456.04a3.14 3.14 0 0 1-3.15-3.128l-.028-8.243z"
			/>
			<g clipPath="url(#o)">
				<path
					fill="#ffe19c"
					d="M142.62 155.192c.047.134.1.257.165.382.068.123.152.234.235.346q.351.504.867.835l.001.001c.492.316 1.044.545 1.672.543l2.058-.007a3.14 3.14 0 0 1-3.15-3.129l-.01-2.878a4.445 4.445 0 0 1 4.429-4.459l11.232-.039-.003-.92-15.676.054-2.058.007.028 8.243c.001.366.089.702.21 1.021"
				/>
			</g>
			<path fill="#eb9300" d="m162.76 139.896-.01-3.026-23.064.079.01 3.026z" />
			<path
				fill="#ffe19c"
				d="m163.528 139.893.01 2.958c.006 1.659-1.273 3.008-2.856 3.014l-18.866.064c-1.583.006-2.871-1.334-2.877-2.993l-.01-2.958z"
			/>
			<g clipPath="url(#p)">
				<path
					fill="#ffe19c"
					d="m141.815 145.93 2.058-.007c-1.583.005-2.871-1.335-2.877-2.994l-.01-2.958-2.058.007.01 2.958c.006 1.659 1.294 2.999 2.877 2.994"
				/>
			</g>
			<path
				fill="#ffe19c"
				d="M150.465 82.976c-2.215.232-5.054 1.256-4.574 2.717.481 1.464 3.38.598 5.301-.533-1.658 1.49-3.359 3.993-2.11 4.894 1.248.9 3.082-1.497 3.973-3.538-.463 2.179-.367 5.195 1.171 5.19 1.539-.006 1.614-3.023 1.135-5.198.906 2.035 2.757 4.419 3.998 3.51 1.243-.909-.475-3.4-2.144-4.878 1.929 1.117 4.834 1.963 5.305.496.47-1.465-2.375-2.469-4.593-2.686 2.216-.232 5.055-1.256 4.574-2.717-.481-1.464-3.38-.598-5.301.533 1.658-1.489 3.36-3.992 2.111-4.893s-3.083 1.496-3.974 3.538c.464-2.179.367-5.196-1.171-5.19s-1.614 3.022-1.135 5.197c-.906-2.035-2.756-4.418-3.998-3.51-1.243.91.476 3.401 2.144 4.879-1.929-1.118-4.834-1.964-5.305-.497-.47 1.465 2.376 2.469 4.593 2.686"
			/>
		</g>
	</svg>
);

export default WasherHomepageIllustrationSVG;
