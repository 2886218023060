import React from "react";
import type { ServiceType } from "../../types";
import ServicePriceCard from "./ServicePriceCard";
// import PriceCard from "./PriceCard";

const PriceList = ({ service }: { service: ServiceType | undefined }) => {
	// const [selectedCategoryName, setSelectedCategoryName] = useState("Tops");
	// const categoryItems = service?.CategoryItem?.find(
	// 	(category) => category.name === selectedCategoryName,
	// )?.items.sort((a, b) => a.title.localeCompare(b.title));

	const sortedServiceOptions = service?.serviceOptions.sort((a, b) =>
		a.title.localeCompare(b.title),
	);

	return (
		<div className="w-full">
			{/* GENERIC SERVICE */}
			<div className="flex flex-col justify-center items-center gap-6">
				{sortedServiceOptions?.map((option) => {
					return (
						<React.Fragment key={option.id}>
							<ServicePriceCard option={option} />
						</React.Fragment>
					);
				})}
			</div>
			{/* ADVANCED SERVICE WITH CATEGORY ITEMS (IRONING) */}
			{/* <div>
        <div className='flex gap-2 py-6 overflow-x-auto'>
          {service?.CategoryItem?.map((category) => {
            return (
              <button
                key={category.id}
                className={`btn btn-sm ${
                  category.name === selectedCategoryName ? 'btn-primary' : ''
                }`}
                onClick={() => setSelectedCategoryName(category.name)}
              >
                {category.name}
              </button>
            );
          })}
        </div>
        <div className='flex flex-col gap-6 justify-center items-center'>
          {selectedCategoryName &&
            categoryItems?.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <PriceCard item={item} isForCategories />
                </React.Fragment>
              );
            })}
        </div>
      </div> */}
		</div>
	);
};

export default PriceList;
